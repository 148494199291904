<template>
  <section
    class="md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 row-span-1"
  >
    <div class="grid grid-cols-2 place-content-center">
      <div
        class="font-nexa font-normal text-white text-xs text-left lg:text-base relative"
        v-if="item"
      >
        {{ `${item.name} ${item.unit ? ` (${item.unit})` : ""} ` }}
        <span
          class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
          >*</span
        >
      </div>

      <div class="flex justify-end items-center">
        <input
          :id="item.id_name"
          :name="item.id_name"
          type="text"
          class="input-standard-transparent md:max-w-236"
          v-model="v$.attribute.$model"
          @blur="v$.attribute.$touch()"
          :class="{ 'error-form-input': v$.attribute.$error }"
          @keyup="format"
        />
      </div>
    </div>
  </section>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("addValueText", me.item.id, me.attribute);
    },
    attribute() {
      const me = this;
      me.$emit("addValueText", me.item.id, me.attribute);
    },
  },
  validations: {
    attribute: { required },
  },
  methods: {
    format() {
      const me = this;

      if (isNaN(me.attribute) || me.attribute > 100 || me.attribute < 0)
        me.attribute = "";
    },
  },
};
</script>
