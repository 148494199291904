import { axios, API_ROUTES } from "@/config";
import { generateHash } from "@/utils";
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("publishFarm", [
      "multimedia",
      "farmSave",
      "isSaveImage",
      "isError",
      "isSuccess",
      "multimediaCopy",
      "abortController",
    ]),
  },
  methods: {
    ...mapActions("publishFarm", [
      "updateIsSaveImage",
      "updateMultimediaCopy",
      "updateAbortController",
      "updateIsError",
      "updateIsSuccess",
    ]),
    abortSaveMultimedia() {
      const me = this;
      if (me.isSaveImage) {
        me.abortController.abort();
        me.updateAbortController(null);
      }
    },
    async saveImages() {
      const me = this;
      let isModified = true;

      if (me.multimediaCopy.length > 0) {
        isModified =
          JSON.stringify(me.multimedia) !== JSON.stringify(me.multimediaCopy);
      }

      if (isModified || me.isError) {
        me.updateIsError(false);
        const abortController = new AbortController();
        me.updateAbortController(abortController);
        try {
          me.updateMultimediaCopy(me.multimedia);
          me.updateIsSaveImage(true);
          const id = me.farmSave.id;
          const dataAditional = await generateHash();
          let formData = new FormData();
          me.multimedia.map((el, index) => {
            if (el.type) {
              let media = null;
              if (el.file) {
                media = el.file;
              } else {
                media = Object.values(el.media);
              }
              const type = el.type == "img" ? 1 : 2;
              const order = index;
              formData.append("multimedia[" + index + "][media]", media);
              formData.append("multimedia[" + index + "][type]", type);
              formData.append("multimedia[" + index + "][order]", order);
            }
          });
          formData.append("id", id);
          formData.append("apiKey", dataAditional.apiKey);
          formData.append("signature", dataAditional.signature);
          formData.append("utcTimeStamp", dataAditional.utcTimeStamp);
          formData.append("last_step", 6);
          await await axios.post(`${API_ROUTES.publishFarm.img}`, formData, {
            signal: abortController.signal,
          });
          me.updateIsSuccess(true);
        } catch (error) {
          me.updateIsSuccess(false);
          const code = error?.response?.status;
          if (code == 401) {
            me.validationExpiredToken();
          }
          if (error?.message !== "canceled") {
            me.updateIsError(true);
          }
        } finally {
          me.updateIsSaveImage(false);
          me.updateAbortController(null);
        }
      }
    },
    closeMessage() {
      const me = this;
      me.updateIsSuccess(false);
    },
  },
};
