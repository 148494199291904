<template>
  <div class="flex flex-col space-y-4 md:pt-5 md:pb-8 md:bg-agrogo-green-5">
    <a
      v-if="item"
      class="hidden md:flex lg:flex font-nexa text-white text-base md:items-start md:px-6"
      >{{ item.name }}
    </a>
    <div class="w-full h-auto md:mt-2 md:px-8 flex justify-center">
      <div class="map" id="map"></div>
    </div>
    <a
      v-if="item"
      class="font-nexa text-sm text-white text-left md:hidden px-8 md:px-5"
      >{{ item.name }}
    </a>
    <a class="font-nexa text-xs text-agrogo-gray-2 text-left px-8 md:hidden"
      >Datos para USO INTERNO DE
      <span class="font-nexa font-black text-xs"> AGRO GO</span>
    </a>
    <div
      class="px-8 md:min-h-64 md:px-5"
      v-for="(option, index) in item.subattributes"
      :key="index"
    >
      <input-decimal
        :item="item"
        :value="option.value"
        :option="option"
        @addSubattributes="addSubattributes"
      />
    </div>
    <a
      class="hidden md:flex lg:flex font-nexa text-base text-agrogo-gray-2 px-5"
      >Datos para USO INTERNO DE
      <span class="font-nexa font-black text-base ml-2"> AGRO GO</span>
    </a>
  </div>
</template>

<script lang="js">
import InputDecimal from "./InputDecimal";
import { Loader } from "@googlemaps/js-api-loader";
import { mapState } from "vuex";

export default {
  components: {
    InputDecimal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    departament: {
      type: String,
      default: null,
    },
    municipality: {
      type: String,
      default: null,
    },
    isChange: {
      type: Boolean,
      default: false,
    },
    isLoadFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_KEY: process.env.VUE_APP_API_KEY_GOOGLE_MAPS,
      coords: {
        lat: 6.33732,
        lng: -75.55795,
      },
      map: null,
      marker: null,
      google: null,
      count: 0,
    };
  },
  watch: {
    departament() {
      const me = this;
      if (me.isChange) me.changeLocation();
    },
    municipality() {
      const me = this;
      if (me.isChange) me.changeLocation();
    },
  },
  computed: {
    ...mapState("publishFarm", ["formData2"]),
  },
  methods: {
    addSubattributes(option, value) {
      const me = this;
      me.$emit("addSubattributes", option, value);
      me.addMarker(option, value);
    },
    initialMap() {
      const me = this;
      const loader = new Loader({
        apiKey: me.API_KEY,
        libraries: ["places"],
      });

      // me.item.subattributes.forEach((el) => {
      //   if (el.name == "Latitud") {
      //     me.coords.lat = el.value;
      //   } else {
      //     me.coords.lng = el.value;
      //   }
      // });

      const mapOptions = {
        center: me.coords,
        zoom: 13,
      };

      loader.load().then((google) => {
        me.google = google;
        me.map = new google.maps.Map(
          document.getElementById("map"),
          mapOptions
        );
        me.createMarker(google, null, me.coords);
      });
    },
    changeLocation() {
      const me = this;
      let request = null;
      let zoom = 0;
      if (me.isChange && me.departament && !me.municipality) {
        const query = `${me.departament}, Colombia`;
        request = {
          query: query,
          fields: ["name", "geometry"],
        };
        zoom = 8;
      } else if (me.isChange && me.municipality) {
        const query = `${me.departament}, ${me.municipality}`;
        request = {
          query: query,
          fields: ["name", "geometry"],
        };
        zoom = 13;
      }

      if (me.isChange) {
        const service = new me.google.maps.places.PlacesService(me.map);
        service.findPlaceFromQuery(request, async (results, status) => {
          if (results && status == "OK") {
            me.marker.setPosition(results[0].geometry.location);
            me.map.setCenter(results[0].geometry.location);
            me.map.setZoom(zoom);
            me.changeInput("", "");
            if (me.municipality) {
              const lat = results[0].geometry.location.lat().toFixed(6);
              const lng = results[0].geometry.location.lng().toFixed(6);
              me.changeInput(lat, lng);
            }
          }
        });
      }
    },
    createMarker(google, place, coords) {
      const me = this;
      me.marker = new google.maps.Marker({
        position: coords ? coords : place.geometry.location,
        map: me.map,
        draggable: true,
        animation: google.maps.Animation.DROP,
      });

      me.marker.addListener("dragend", (evt) => {
        const lat = evt.latLng.lat().toFixed(6);
        const lng = evt.latLng.lng().toFixed(6);
        me.changeInput(lat, lng);
        me.searchLocation(lat, lng);
      });
    },
    async searchLocation(lat, lng) {
      const me = this;
      me.$emit("updateIsChange", false);
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&&result_type=political&region=CO&result_type=locality&result_type=sublocality&key=${me.API_KEY}`
        );
        const data = await response.json();
        if (data.status === "OK") {
          // Aquí puedes manejar la respuesta de la API de Geocodificación
          const result = data.results[0].formatted_address
            .split(",")
            .reverse()
            .splice(1, 2);

          let depart = null;
          let munip = null;

          if (result.length == 2) {
            depart = result[0].trim();
            munip = result[1].trim();
          }
          me.$emit("changeSelect", depart, munip);
        } else {
          console.error("Error al realizar la geocodificación:", data.status);
        }
      } catch (error) {
        console.error("Error al realizar la petición:", error);
      } finally {
        me.count = 0;
      }
    },
    changeInput(lat, lng) {
      const me = this;
      const newLat = lat ? parseFloat(lat) : "";
      const newLng = lng ? parseFloat(lng) : "";
      me.item.subattributes.forEach((el) => {
        if (el.name == "Latitud") {
          me.$emit("addSubattributes", el, newLat);
        } else {
          me.$emit("addSubattributes", el, newLng);
        }
      });
    },
    addMarker(option, value) {
      const me = this;
      if (option.name == "Latitud") {
        me.coords.lat = value;
      } else {
        me.coords.lng = value;
      }
      me.count++;

      if (me.isLoadFirst && me.count == 2) me.$emit("updateIsLoadFirst", false);

      if (me.marker && me.coords.lat && me.coords.lng) {
        me.marker.setPosition(me.coords);
        me.map.setCenter(me.coords);

        if (!me.isLoadFirst && me.count == 2) {
          me.searchLocation(me.coords.lat, me.coords.lng);
        }
      }

      if (me.count == 2) me.count = 0;
    },
  },
  mounted() {
    const me = this;
    me.initialMap();
  },
};
</script>

<style lang="css">
@media only screen and (max-width: 768px) {
  .map {
    width: 100%;
    height: 200px;
  }
}
@media only screen and (min-width: 768px) {
  .map {
    width: 100%;
    height: 429.25px;
  }
}
</style>
