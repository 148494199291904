<template>
  <div>
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section
      v-else
      class="pt-7 md:pt-7 md:pb-20 md:space-x-2 md:justify-center padding-publish-farms"
    >
      <div
        class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 lg:gap-x-3 pb-16"
      >
        <div class="px-4 md:px-0">
          <div
            class="grid grid-cols-2 md:min-h-64 md:bg-agrogo-green-5 md:px-5 mb-4"
            v-if="step && step.id == 1"
          >
            <div class="flex items-center justify-start">
              <a class="font-nexa text-white text-sm text-left lg:text-lg flex">
                Departamento
                <span
                  class="text-agrogo-yellow-2 text-2xl ml-1 h-0 inline-block"
                  >*</span
                >
              </a>
            </div>

            <div class="flex justify-end items-center">
              <v-select
                label="name"
                class="style-chooser2 w-full md:max-w-236 text-xs"
                :options="departments"
                v-model="v$.formData.selectedDepart.$model"
                @search:blur="v$.formData.selectedDepart.$touch()"
                @update:modelValue="loadMunicipies()"
                :class="{
                  'error-form-select ': v$.formData.selectedDepart.$error,
                }"
              >
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
          <div
            class="grid grid-cols-2 md:min-h-64 md:bg-agrogo-green-5 md:px-5 mb-4"
            v-if="step && step.id == 1"
          >
            <div class="flex items-center justify-start">
              <a class="font-nexa text-white text-sm text-left lg:text-lg flex"
                >Municipio
                <span
                  class="text-agrogo-yellow-2 text-2xl ml-1 h-0 inline-block"
                  >*</span
                >
              </a>
            </div>

            <div class="flex justify-end">
              <v-select
                :options="municipalities"
                class="style-chooser2 w-full md:max-w-236 text-xs"
                label="name"
                v-model="v$.formData.municipality_id.$model"
                @search:blur="v$.formData.municipality_id.$touch()"
                @update:modelValue="addMunicipy()"
                :class="{
                  'error-form-select ': v$.formData.municipality_id.$error,
                }"
              >
                <template #no-options>Sin opciones</template>
              </v-select>
            </div>
          </div>
          <div class="">
            <template v-for="(item, index) in attributes" :key="index">
              <input-text
                :item="item"
                :value="item.value"
                :index="index"
                @addValueText="addValueText"
                v-if="
                  item.type_attributes_input.name == 'Text' && item.id !== 5
                "
              />
              <input-number-distance
                :item="item"
                :value="item.value"
                :index="index"
                @addValue="addValue"
                v-if="item.type_attributes_input.name == 'Number'"
              />
            </template>
          </div>
        </div>
        <div>
          <div v-for="(item, index) in attributes" :key="index">
            <location-component
              :item="item"
              :departament="formData.selectedDepart?.name"
              :municipality="formData.municipality_id?.name"
              :isChange="isChange"
              :isLoadFirst="isLoadFirst"
              @addSubattributes="addSubattributes"
              @changeSelect="changeSelect"
              @updateIsChange="updateIsChange"
              @updateIsLoadFirst="isLoadFirst = false"
              v-if="item.id_name == 'UBICACION_SATELITAL'"
            />
          </div>
        </div>
      </div>
    </section>
    <message-multimedia v-if="isSaveImage || isError || isSuccess" />
    <div
      class="bg-agrogo-yellow-1 h-14 space-x-3 md:space-x-4 lg:space-x-4 sticky bottom-0 z-50 z-50"
      :class="{ 'h-16 lg:h-20 md:h-20': isInvalid }"
    >
      <div class="flex items-center justify-center pt-2">
        <button
          @click.prevent="previus()"
          type="button"
          class="btn-primary mr-4"
        >
          <span
            class="icon-desplegar flex items-center transform -rotate-180 text-agrogo-yellow-2 text-xs mr-1 leading-none"
          ></span>
          Anterior
        </button>
        <button
          @click.prevent="next()"
          :disabled="isBusy2"
          type="button"
          class="btn-primary"
        >
          <div class="loader" v-if="isBusy2" />
          <div v-else class="flex">
            Siguiente
            <span
              class="icon-desplegar flex items-center transform text-agrogo-yellow-2 text-xs ml-1 leading-none"
            ></span>
          </div>
        </button>
      </div>
      <p
        v-if="v$.$invalid"
        class="pt-1 pb-1 font-nexa font-normal text-xs lg:text-base md:text-base text-agrogo-green-2 text-left flex justify-center"
      >
        Completa los campos obligatorios (<span class="text-3xl ml-0 mr-0 h-0"
          >*</span
        >) para continuar.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import publishFarmMixin from "@/mixins/publishFarmMixin";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
import saveImageMixin from "@/mixins/saveImageMixin";
//Vuelidate
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// Components
import InputNumberDistance from "../globals/InputNumberDistance";
import LocationComponent from "../globals/LocationComponent";
import InputText from "../globals/InputText";
import MessageMultimedia from "../globals/MessageMultimedia";
export default {
  inject: ["provider"],
  components: {
    InputNumberDistance,
    LocationComponent,
    InputText,
    MessageMultimedia,
  },
  mixins: [publishFarmMixin, beforeWindowMixin, saveImageMixin],
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      repository: me.provider.publishFarmRepository,
      repositoryEdit: me.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      isBusy: true,
      isBusy2: false,
      isChange: false,
      departments: [],
      municipalities: [],
      formData: {
        municipality_id: null,
        selectedDepart: null,
        value_attributes: [],
      },
      step2: true,
      dirty: true,
      nextStep: null,
      isLoadFirst: false,
    };
  },
  computed: {
    ...mapState("publishFarm", ["step", "formData2", "formData3", "farmSave"]),
    isInvalid() {
      const me = this;
      return me.v$.$invalid;
    },
  },
  validations: {
    formData: {
      municipality_id: {
        required,
      },
      selectedDepart: {
        required,
      },
      // nearby_municipality_id: {
      //   required,
      // },
    },
    attributeValidations: {
      $each: helpers.forEach({
        value: {
          required,
        },
      }),
    },
  },
  methods: {
    ...mapActions("publishFarm", [
      "findStep",
      "addFormData2",
      "updateMessage",
      "updateIsStep",
    ]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async next() {
      const me = this;
      me.v$.$touch();
      if (!me.v$.$invalid) {
        if (
          me.step.number2 == 3 ||
          me.step.number2 == 4 ||
          me.farmSave?.isFarmSave
        ) {
          await me.save();
        } else {
          me.findStep(me.step.number2 + 1);
        }
      }
    },
    async loadDepartments() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDeparts(dataAditional);
        me.departments = data;
      } catch (error) {
        // console.log(error);
      }
    },
    async loadMunicipies() {
      const me = this;
      me.isChange = true;
      me.resetSelect();
      me.municipalities = me.formData.selectedDepart?.municipalities;
    },
    resetSelect() {
      const me = this;
      me.municipalities = [];
      me.formData.municipality_id = null;
    },
    addMunicipy() {
      const me = this;
      me.isChange = true;
      me.addFormData2(me.formData);
    },
    async fillFormData() {
      const me = this;
      if (me.formData2) {
        me.isLoadFirst = true;
        me.isChange = false;
        if (me.formData2.selectedDepart) {
          if (me.slug && typeof me.formData2.selectedDepart == "number") {
            const depart = me.departments.find(
              (el) => el.id == me.formData2.selectedDepart
            );

            if (depart) me.formData.selectedDepart = depart;
            me.municipalities = me.formData.selectedDepart.municipalities;
            const munip = me.municipalities.find(
              (el) => el.id == me.formData2.municipality_id
            );

            if (munip) me.formData.municipality_id = munip;
          } else {
            me.formData.selectedDepart = me.formData2.selectedDepart;
            me.municipalities = me.formData.selectedDepart?.municipalities;
            me.formData.municipality_id = me.formData2.municipality_id;
          }
        }
        me.formData.value_attributes = me.formData2.value_attributes;
        me.attributes.map((el) => {
          me.formData.value_attributes.map((x) => {
            if (x.attribute_id == el.id) {
              if (el.subattributes.length > 0) {
                el.subattributes.map((y) => {
                  if (y.id == x.subattribute_id) {
                    y.value = x.value;
                  }
                });
              } else {
                el.value = x.value;
              }
            }
            // Campos requeridos
            me.attributeValidations.map((z) => {
              if (x.attribute_id == z.attribute_id) {
                z.value = x.value;
              }
            });
          });
        });
      }
    },
    updateIsChange(status) {
      const me = this;
      me.isChange = status;
    },
    changeSelect(depart, munip) {
      const me = this;
      let muni = null;
      let dep = null;
      let isNot = true;
      me.formData.selectedDepart = null;

      if (depart) {
        dep = me.departments.find(
          (el) =>
            el.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ==
            depart
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
        );

        if (depart == "Bogotá" || depart == "Bogota") {
          dep = me.departments.find((el) => el.name == "Cundinamarca");
          muni = dep.municipalities.find((el) => el.name == "Bogotá D.C.");
          isNot = false;
        }

        me.municipalities = dep?.municipalities;
      }

      if (munip && isNot) {
        muni = dep?.municipalities.find(
          (el) =>
            el.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ==
            munip
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
        );

        if (
          munip == "Provincia de Cartagena" ||
          munip == "Cartagena Province" ||
          munip == "Cartagena de Indias"
        ) {
          muni = dep.municipalities.find(
            (el) => el.name == "Cartagena De Indias"
          );
        }
      }

      me.formData.selectedDepart = dep ? dep : null;
      me.formData.municipality_id = muni ? muni : null;
    },
    // useGeolocation() {
    //   const me = this;
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       const { latitude, longitude } = position.coords;
    //       me.coords.lat = latitude;
    //       me.coords.lng = longitude;
    //     });
    //   }
    // },
  },
  created() {
    const me = this;
    me.nextStep = me.step.number2 + 1;
    if (me.step.number2 == 4) {
      me.updateMessage(
        `¿Salir de la página? Si abandonas perderas los cambios que realizaste en este paso ${me.step.number2}.`
      );
    } else {
      me.updateMessage(
        "¿Salir de la página? Si ya pasaste el paso 3 tu finca ha quedado guardada. De lo contrario si abandonas se perdera la información que llevas."
      );
    }
    window.addEventListener("beforeunload", me.beforeWindowUnload);
  },
  beforeUnmount() {
    const me = this;

    me.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    me.updateIsStep(true);
    window.scroll(0, 0);
    await me.loadAttributes();
    await me.loadDepartments();
    if (me.slug) {
      await me.loadAttributesEdit();
    }
    me.fillFormData();
  },
};
</script>
