<template>
  <section>
    <div class="panel-img-video">
      <img
        v-if="record?.type == 'img'"
        class="w-auto h-full rounded-xl items-center object-cover"
        :src="record?.src"
        loading="lazy"
        data-src="@/assets/images/loading.gif"
        data-srcset="@/assets/images/loading.gif"
        alt=""
      />
      <video
        v-else
        class="w-full h-full rounded-xl"
        preload="auto"
        poster="@/assets/images/loading.gif"
        controls
        autoplay
        muted
        loop
        playsinline
        id="video-detail"
        aria-describedby="agrogo"
      >
        <source src="" class="w-full h-full" type="video/mp4" />
        <track label="Español" kind="captions" srclang="es" src="" />
      </video>
    </div>
    <div class="footer-multimedia" v-if="records.length > 1">
      <div class="flex items-center justify-center pt-2">
        <button
          type="button"
          class="btn-slider-multimedia mr-4"
          @click.prevent="previus"
          :disabled="index < 2"
        >
          <span
            class="icon-desplegar flex items-center transform -rotate-180 text-xs mr-1 leading-none"
          ></span>
        </button>
        <button
          type="button"
          class="btn-slider-multimedia"
          @click.prevent="next"
          :disabled="index == records.length"
        >
          <span
            class="icon-desplegar flex items-center transform text-xs ml-1 leading-none"
          ></span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    // record: {
    //   type: Object,
    //   default: null,
    // },
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      record: {
        src: require("@/assets/images/previewimg.png"),
        type: "img",
      },
      index: 1,
    };
  },
  watch: {
    records() {
      const me = this;
      me.index = 1;
      me.loadFindImgOrVideo();
    },
  },
  methods: {
    previus() {
      const me = this;
      me.index--;
      me.findRecord();
    },
    next() {
      const me = this;
      me.index++;
      me.findRecord();
    },
    findRecord() {
      const me = this;
      me.record = me.records[me.index - 1];
      if (me.record.type == "video/mp4") {
        setTimeout(() => {
          me.loadVideo2();
        }, 100);
      }
    },
    loadFindImgOrVideo() {
      const me = this;
      if (me.records.length > 0) {
        const video = me.records.find((el) => el.type == "video/mp4");
        if (video) {
          me.record = video;
          setTimeout(() => {
            me.loadVideo2();
          }, 100);
        } else {
          me.record = me.records[0];
        }
      } else {
        me.record = {
          src: require("@/assets/images/previewimg.png"),
          type: "img",
        };
      }
    },
    loadVideo2() {
      const me = this;
      // Obtén la etiqueta de video por su ID
      const video = document.getElementById("video-detail");
      // Obtén el elemento <source> dentro de la etiqueta de video
      const source = video?.querySelector("source");

      // Asigna la fuente original nuevamente
      source.src = me.record.src;
      video?.load();
      video?.play();
    },
  },
  mounted() {
    const me = this;
    me.loadFindImgOrVideo();
  },
};
</script>

<style lang="css">
.btn-slider-multimedia {
  width: 50px;
  height: 30px;
  border-radius: 0.5rem;
  background-color: #153e35;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-slider-multimedia:disabled {
  opacity: 0.6;
  background-color: #153e35;
  cursor: not-allowed;
}
</style>
