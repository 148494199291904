<template>
  <div
    class="md:min-h-64 md:bg-agrogo-green-5 md:px-5 lg:col-span-2 md:col-span-2 col-span-1"
  >
    <div class="content-component mb-5">
      <a
        class="font-nexa text-white text-sm text-left lg:text-base mb-4 relative"
        >Cultivos Maderables
        <span
          class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
          >*</span
        >
      </a>
      <div class="md:w-2/4 lg:w-2/4 w-full">
        <v-select
          :options="options"
          v-model="v$.selected.$model"
          @search:blur="v$.selected.$touch()"
          :class="{
            'error-form-select-multiple': v$.selected.$error,
          }"
          placeholder="Escoger tipo de Cultivo"
          class="style-chooser3 w-full text-right"
          label="name"
          :close-on-select="false"
          multiple
          @update:modelValue="change()"
        >
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
    </div>
    <div class="table-container">
      <table
        class="table-auto table-cebra"
        aria-describedby=""
        v-if="selected.length > 0"
      >
        <thead>
          <tr>
            <th></th>
            <th>Edad miníma de Cultivo <br />(Años)</th>
            <th>Edad maxíma de Cultivo <br />(Años)</th>
            <th>Cant. de árboles <br />(Unidades)</th>
            <th>Hectáreas destinadas <br />(Ha)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in selected" :key="item?.id">
            <td v-if="item.id == 156">
              <input
                type="text"
                class="input-table input-standard-transparent w-16 md:w-28 lg:w-28"
                v-model="item.value"
                :class="{ 'error-form-input': item.value == '' }"
                @keyup="
                  () =>
                    $emit(
                      'addValueOthers',
                      item.attribute_id,
                      item.id,
                      item.value
                    )
                "
              />
            </td>
            <td v-else>{{ item?.name }}</td>
            <td v-for="(att, index) in item?.subattributes" :key="index">
              <input
                type="text"
                class="input-table input-standard-transparent w-16 md:w-28 lg:w-28"
                v-model="att.value"
                @keyup="format(item.id, att.id)"
                :class="{ 'error-form-input': att.value == '' }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  emits: ["addSubattributes2", "addValidationsAttributes", "addValueOthers"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    firstLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      attribute: this.value,
      selected: [],
      options: [],
    };
  },
  watch: {
    firstLoad() {
      const me = this;
      if (me.firstLoad) me.loadData();
    },
  },
  validations: {
    selected: { required },
  },
  methods: {
    change() {
      const me = this;
      me.$emit("addValidationsAttributes", me.selected, me.item);
    },
    format(id, idSub) {
      const me = this;
      me.selected.forEach((el) => {
        if (el.id == id) {
          el.subattributes.forEach((x) => {
            if (x.id == idSub) {
              x.value = me.formatValue(x.value);
              x.value = x.value == "0" ? "" : x.value;
              me.$emit("addSubattributes2", el, idSub, x.value);
            }
          });
        }
      });
    },
    formatValue(value) {
      let attribute = 0;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      const area10 = value.split(",");
      if (area10.length > 2) {
        value = `${area10[0]}${area10[1] !== "" ? `,${area10[1]}` : ","}`;
      }
      let area = value.replace(/[^\d,.]/g, "");
      let area2 = value.replace(/[^\d,.]/g, "");
      let isValidate = true;

      if (value.split(".")[1]) {
        area = value
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (value.split(",")[1]) {
        if (value.split(".")[1]) {
          const area3 = value
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        } else {
          const area3 = value.split(",")[0].replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = value
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area2;
      return attribute;
    },
    loadData() {
      const me = this;
      const levels1 = [];
      const levels2 = [];
      me.options = [];
      me.item.subattributes.forEach((el) => {
        if (el.level == 1) {
          levels1.push({
            ...el,
            subattributes: [],
          });
        } else {
          levels2.push(el);
        }
      });
      levels2.forEach((el) => {
        const record = levels1.find((x) => x.id == el.subattribute_id);
        if (record) {
          const item = me.options.find((x) => x.id == record.id);
          if (item) {
            item.subattributes.push(el);
          } else {
            record.subattributes.push(el);
            me.options.push(record);
          }
        }
      });
      me.options.forEach((el) => {
        el.subattributes.sort((a, b) => {
          if (a.org_id > b.org_id) {
            return 1;
          }
          if (a.org_id < b.org_id) {
            return -1;
          }
          return 0;
        });
        const record = el.subattributes.find((x) => x.value !== "");
        if (record) me.selected.push(el);
      });
    },
  },
  mounted() {
    const me = this;
    me.loadData();
  },
};
</script>
<style lang="css">
.content-component {
  display: flex;
  justify-content: space-between;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}
/* Tamaño del scroll */
.table-container::-webkit-scrollbar {
  width: 37px;
  height: 5px;
}
/* Estilos barra (thumb) de scroll */
.table-container::-webkit-scrollbar-thumb {
  background: #f5ce3e !important;
  border-radius: 11px;
}

.table-container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #f5ce3e !important;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.table-cebra {
  width: 100%;
  border: 0;
}

.table-cebra thead {
  background-color: #23443d;
  border-bottom: 3px solid #153e35;
}

.table-cebra thead tr th {
  width: 200px;
}

.table-cebra tbody tr {
  background: rgba(196, 196, 196, 0.1) !important;
  border-bottom: 3px solid #153e35;
  color: white;
}

.table-cebra tbody tr:nth-child(2n) {
  background-color: #23443d !important;
}

.table-cebra th {
  background-color: transparent;
  color: #e5e5e5 !important;
  font-size: 14px;
  font-family: "Nexa", sans-serif;
  font-weight: 400;
  text-align: center !important;
}

.table-cebra th,
.table-cebra td {
  padding: 0.5rem;
  text-align: center;
}

.table-cebra td:first-child {
  text-align: left !important;
}

.table-cebra thead tr th:first-child {
  width: 400px;
}

.input-table {
  background: #153e35 !important;
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .content-component {
    flex-direction: column;
  }
}
</style>
