<template>
  <section class="pb-5 mt-4 flex justify-center">
    <div class="content-message-multimedia">
      <img src="@/assets/images/alerta2.svg" alt="" v-if="isError" />
      <svg class="loader-message" viewBox="25 25 50 50" v-else-if="isSaveImage">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
      <img src="@/assets/images/success2.svg" alt="" v-else />
      <h1 v-if="isSaveImage">
        No recargues la página, fotos y vídeos en proceso de subida.
      </h1>
      <h1 v-else-if="isError">
        La carga de fotos y vídeos ha fallado por problemas de conexión.
      </h1>
      <h1 v-else>
        ¡La carga de fotos y vídeos ha sido exitosa!, puedes continuar.
      </h1>
      <button @click.prevent="saveImages" v-if="isError">Reintentar</button>
      <button @click.prevent="updateIsSuccess(false)" v-else-if="isSuccess">
        Cerrar
      </button>
    </div>
  </section>
</template>

<script>
import saveImageMixin from "@/mixins/saveImageMixin";
export default {
  mixins: [saveImageMixin],
};
</script>

<style lang="css">
/* //Message multimedia */
.content-message-multimedia {
  min-height: 56px;
  background-color: #00754a;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  padding: 10px;
}
.content-message-multimedia h1 {
  font-weight: 400;
  font-size: 18px;
  color: white;
  font-family: "Nexa" Arial, Helvetica, sans-serif;
  text-align: left;
  margin-left: 8px;
}
.content-message-multimedia button {
  background-color: #e9b803;
  width: 138px;
  height: 35px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #153e35;
  font-family: "Nexa" Arial, Helvetica, sans-serif;
  margin-left: 16px;
}
.loader-message {
  width: 32px;
  height: 32px;
  transform-origin: center;
  animation: rotate4 1s linear infinite;
}

circle {
  fill: none;
  stroke: #f5ce3e;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

@media (max-width: 767px) {
  .content-message-multimedia {
    width: 90%;
    justify-content: flex-start;
  }
  .content-message-multimedia h1 {
    font-size: 12px;
  }
  .content-message-multimedia button {
    background-color: #e9b803;
    width: 138px;
    height: 30px;
    font-size: 12px;
    margin-left: 16px;
  }
}
</style>
