<template>
  <div
    class="md:min-h-64 md:bg-agrogo-green-5 md:px-5 lg:col-span-1 md:col-span-2 row-span-1"
  >
    <div
      class="flex flex-col md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:px-0 lg:px-0"
    >
      <div class="flex items-center justify-between">
        <a
          v-if="item"
          class="font-nexa text-white text-sm text-left lg:text-base relative"
          >{{ item.name }} {{ item.unit ? `(${item.unit})` : "" }}
          <span
            class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
            >*</span
          >
        </a>
      </div>
      <div class="grid grid-cols-2">
        <div
          class="flex items-center text-left space-x-2 mb-5"
          v-for="(option, index) in item.subattributes"
          :key="index"
        >
          <input-check
            :item="item"
            :option="option"
            :value="option.value == '' ? false : option.value"
            @addCheck="addCheck"
            :className="{ 'error-form-input': v$.options.$error }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheck from "./InputCheck";
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  emits: ["addSubattributesCheck"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputCheck,
  },
  data() {
    return {
      v$: useVuelidate(),
      options: [],
    };
  },
  watch: {
    isEdit() {
      const me = this;
      if (me.isEdit) {
        me.item.subattributes.forEach((el) => {
          if (el.value) me.options.push(el);
        });
      }
    },
  },
  validations: {
    options: { required },
  },
  methods: {
    addCheck(option, value) {
      const me = this;
      const opt = me.options.find((el) => el.id === option.id);
      const optIndex = me.options.findIndex((el) => el.id === option.id);
      if (value) {
        if (!opt) me.options = [...me.options, option];
      } else {
        me.options.splice(optIndex, 1);
      }
      me.$emit("addSubattributesCheck", option, value);
    },
  },
};
</script>
