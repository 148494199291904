<template>
  <button class="card-img" id="card-file" style="relative" @click="onFile">
    <div
      class="flex items-center w-full h-full text-lg text-agrogo-black-1 px-2 py-2 cursor-pointer overflow-hidden"
    >
      <div class="flex flex-col">
        <div class="icon-imgadd" />
        <span class="font-nexa text-xs md:text-sm text-agrogo-black-1">
          Cargar Video y fotos
        </span>
        <input
          id="file-input"
          ref="fileInput"
          type="file"
          class="hidden"
          name="file0"
          accept=".jpg, .png, .jpeg, video/*"
          @change="fileChange"
          :disabled="isBusy"
          multiple
        />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fileChange(e) {
      const me = this;
      me.$emit("fileChange", e);
      const inputFile = document.getElementById("file-input");
      inputFile.value = "";
    },
    onFile() {
      this.$refs.fileInput.click();
    },
  },
  mounted() {
    const cardFile = document.getElementById("card-file");
    if (cardFile) cardFile.removeAttribute("data-draggable");
  },
};
</script>
