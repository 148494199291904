<template>
  <div
    class="place-content-center md:min-h-64 md:bg-agrogo-green-5 md:px-5 row-span-2"
  >
    <div class="flex">
      <div class="flex items-center md:pb-4">
        <a
          class="font-nexa font-bold text-white text-sm text-left md:text-base lg:text-lg"
          v-if="item"
          >{{ item.name }}
        </a>
        <div class="tooltip-container pl-2 md:pl-3">
          <i class="fas fa-question-circle tooltip-trigger text-white">
            <span
              class="icon-Group text-agrogo-yellow-2 text-lg md:text-2xl ml-1.5 transform transition-all"
            ></span>
          </i>
          <div class="tooltip-step3">1 cuadra = 0.64 hectáreas</div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 mt-2 mb-4">
      <div class="flex flex-col justify-start items-start">
        <span
          class="font-dinpro font-normal text-white text-xs text-left md:text-sm capitalize relative"
        >
          {{ item.unit ? `${item.unit}` : "" }}
          <span
            class="text-agrogo-yellow-2 text-xl ml-2 h-0 relative top-1 left-[-5px]"
            >*</span
          >
        </span>
      </div>

      <div class="flex justify-end items-center">
        <input
          :id="item.id_name"
          :name="item.id_name"
          type="text"
          class="input-standard-transparent md:max-w-236"
          v-model="v$.attribute.$model"
          @blur="v$.attribute.$touch()"
          :class="{ 'error-form-input': v$.attribute.$error }"
          @keyup="format"
        />
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div class="flex justify-start items-center">
        <a
          class="font-dinpro font-normal text-white text-xs text-left md:text-sm"
          >Cuadras, Plazas, Fanegadas</a
        >
      </div>

      <div class="flex justify-end items-center">
        <input
          type="text"
          class="input-standard text-white bg-agrogo-gray-1-opacity md:max-w-236 opacity-80 cursor-not-allowed"
          :value="hectaresBlocks(attribute)"
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("addValue", me.item.id, me.attribute);
    },
  },
  validations: {
    attribute: { required },
  },
  methods: {
    format() {
      const me = this;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      let area = me.attribute.replace(/[^\d,.]/g, "");
      let area2 = me.attribute.replace(/[^\d,.]/g, "");
      let attribute = 0;
      let isValidate = true;

      if (me.attribute.split(".")[1]) {
        area = me.attribute
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (me.attribute.split(",")[1]) {
        if (me.attribute.split(".")[1]) {
          const area3 = me.attribute
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        } else {
          const area3 = me.attribute.split(",")[0].replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = me.attribute
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area2;

      me.attribute = attribute;
      me.$emit("addValue", me.item.id, me.attribute);
    },
    hectaresBlocks(value) {
      if (value) {
        let isValidate = true;
        const formatter = new Intl.NumberFormat("es-CO", {
          minimumFractionDigits: 0,
        });

        if (value.split(",")[1]) {
          isValidate = false;
          value = value.split(".").join("").split(",").join(".");
        }

        if (isNaN(value.split(".").join(""))) {
          value = 0;
        } else if (value.split(".")[1] && isValidate) {
          value = value.split(".").join("");
        }

        value = (value * (1 / 0.64)).toFixed(1);

        let result = formatter.format(value);
        return result;
      }
    },
  },
};
</script>
