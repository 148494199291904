<template>
  <section
    class="md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 lg:col-span-1 md:col-span-2 row-span-1"
  >
    <div class="grid grid-cols-2 place-content-center">
      <div class="flex items-center">
        <a class="font-nexa text-white text-sm text-left lg:text-base relative"
          >{{ item.name }}
          <span
            class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
            >*</span
          ></a
        >
      </div>

      <div class="flex items-center justify-end">
        <v-select
          :options="item.subattributes"
          v-model="v$.selected.$model"
          @search:blur="v$.selected.$touch()"
          :class="{
            'error-form-select': v$.selected.$error,
          }"
          :reduce="(option) => option.id.toString()"
          class="style-chooser2 w-full md:max-w-236"
          label="name"
        >
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
    </div>
    <div
      v-if="item.id_name == 'IS_VISIBLE'"
      class="flex items-center pb-2 mt-4 md:pl-2 lg:pl-2 justify-between"
    >
      <span
        v-if="selected == '421'"
        class="font-dinpro font-normal text-white text-xs text-left md:text-base"
      >
        Al seleccionar No Visible tu finca, no saldrá Publicada, la tendremos
        Privada para Clientes Potenciales. Esto tiene un impacto en el número de
        Personas que podrían visualizar tu Finca.
      </span>
    </div>
  </section>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      selected: me.value == "" ? null : me.value,
    };
  },
  watch: {
    selected() {
      const me = this;
      me.$emit("addSubattributesSelect", me.item.id, me.selected);
    },
    value(newValue) {
      const me = this;
      me.selected = newValue;
    },
  },
  validations: {
    selected: { required },
  },
};
</script>
