<template>
  <div
    class="bg-agrogo-yellow-1 h-20 flex items-center justify-center md:h-24 w-full relative"
  >
    <div class="flex items-center w-7/12 lg:w-10/12 md:w-9/12 justify-center">
      <div class="flex flex-col justify-center">
        <div class="flex items-star justify-star pb-3">
          <span
            class="font-nexa font-normal text-base text-agrogo-green-2 md:text-2xl"
          >
            <span class="font-nexa">
              <strong class="font-black">Publicar</strong> Finca
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <section>
    <div
      class="flex items-center justify-center px-4 h-14 bg-agrogo-green-3 md:mx-16 lg:mx-24 xl:mx-40 md:h-14 md:mt-11 md:pl-16"
    >
      <h1 class="font-nexa text-white text-sm md:text-2xl">
        Te invitamos a
        <strong>Publicar tu Finca en AGRO GO por Tiempo ilimitado </strong>
      </h1>
    </div>
    <div
      class="flex justify-center md:px-5 md:py-6 md:mx-16 lg:mx-24 xl:mx-40 vh-40"
    >
      <div
        class="flex flex-col px-4 pt-4 md:pt-6 lg:pt-6 space-y-3 mb-6 md:mb-0 lg:mb-0"
      >
        <div class="flex items-center px-3">
          <span
            class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></span>
          <span
            class="font-dinpro font-normal text-white text-sm text-left lg:text-lg"
            >1. Somos <strong>100% Especializados</strong> en Fincas de Trabajo
            a Nivel Nacional.
          </span>
        </div>
        <div class="flex items-center px-3">
          <span
            class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></span>
          <span
            class="font-dinpro font-normal text-white text-sm text-left lg:text-lg"
            >2. Selecciona tú Finca <strong>Publica o Privada</strong> (no
            saldrá visible)
          </span>
        </div>
        <div class="flex items-center px-3">
          <span
            class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 lg:mt-1"
          ></span>
          <span
            class="font-dinpro font-normal text-white text-sm text-left lg:text-lg"
            >3. <strong>Únicamente</strong> si se vende La Finca, cobramos el
            <strong>4% sobre el Valor del Negocio</strong>
          </span>
        </div>
        <div class="flex items-center px-3">
          <span
            class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></span>
          <span
            class="font-dinpro font-normal text-white text-sm text-left lg:text-lg"
            >4. Tenemos más de <strong>1 Millón de Visualizaciones</strong> en
            nuestros Canales Digitales
          </span>
        </div>
        <div class="flex items-center px-3">
          <span
            class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></span>
          <span
            class="font-dinpro font-normal text-white text-sm text-left lg:text-lg"
            >5. Recuerda Tener <strong>Fotos y Videos</strong> Generales de
            Finca para poder Publicarla
          </span>
        </div>
      </div>
    </div>
  </section>
  <!-- CONTENEDOR 3 BOTONES NAVEGACION -->
  <section>
    <div
      class="bg-agrogo-yellow-1 h-14 space-x-3 md:space-x-4 lg:space-x-4 sticky bottom-0 z-50 z-50"
    >
      <div class="flex items-center justify-center pt-2">
        <button
          @click.prevent="() => $emit('nextStep1')"
          type="button"
          class="btn-primary"
        >
          <div class="flex">
            Continuar
            <span
              class="icon-desplegar flex items-center transform text-agrogo-yellow-2 text-xs ml-1 leading-none"
            ></span>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  emits: ["nextStep1"],
};
</script>
