<template>
  <div>
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section
      v-else
      class="px-4 pt-7 pb-10 md:pt-7 lg:pt-7 md:pb-20 lg:pb-20 md:space-x-2 lg:space-x-2 md:justify-center lg:justify-center md:px-0"
    >
      <div
        class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-6 padding-publish-farms"
      >
        <template v-for="(item, index) in attributes" :key="index">
          <input-number
            :item="item"
            :value="item.value"
            :index="index"
            @addValue="addValue"
            v-if="item.type_attributes_input.name == 'Number'"
          />
          <input-select
            :item="item"
            :value="item.value"
            :index="index"
            @addSubattributesSelect="addSubattributesSelect"
            v-else-if="item.type_attributes_input.name == 'Select'"
          />
          <inputs-height
            :item="item"
            :index="index"
            @setHeight="setHeight"
            @addSubattributes="addSubattributes"
            v-else-if="item.type_attributes_input.name == 'From-To'"
          />
          <topography-component
            :item="item"
            @setTotal="setTotal"
            v-else-if="item.type_attributes_input.name == 'Summation'"
            @addSubattributes="addSubattributes"
          />
          <input-check-multiple
            :item="item"
            :isEdit="isEditCheck"
            :index="index"
            @addSubattributesCheck="addSubattributesCheck"
            v-else-if="item.type_attributes_input.name == 'Checkbox'"
          />
          <input-text-area
            :item="item"
            :value="item.value"
            :index="index"
            @addValueText="addValueText"
            v-else-if="item.type_attributes_input.name == 'Textarea'"
          />
          <input-text
            :item="item"
            :value="item.value"
            :index="index"
            @addValueText="addValueText"
            v-if="item.type_attributes_input.name == 'Text' && item.id !== 5"
          />
          <input-number-percentage
            :item="item"
            :value="item.value"
            :index="index"
            @addValueText="addValueText"
            v-else-if="item.type_attributes_input.name == 'Percentage-Number'"
          />
          <select-multiple
            :item="item"
            :values="item.value"
            @addSubattributesSelectMultiple="addSubattributesSelectMultiple"
            v-else-if="item.type_attributes_input.name == 'Select-multiple'"
          />
          <table-two-column
            :item="item"
            @addValueText="addValueText"
            v-else-if="
              item.name == 'table-two-column' &&
              item.type_attributes_input.name == 'Table-Columns-2'
            "
          />
          <table-four-column
            :item="item"
            :firstLoad="firstLoad"
            @addSubattributes2="addSubattributes2"
            @addValidationsAttributes="addValidationsAttributes"
            @addValueOthers="addValueOthers"
            v-else-if="item.type_attributes_input.name == 'Table-Columns-4'"
          />
          <table-three-column
            :item="item"
            :firstLoad="firstLoad"
            @addSubattributes2="addSubattributes2"
            @addValidationsAttributes="addValidationsAttributes"
            @addValueOthers="addValueOthers"
            v-else-if="item.type_attributes_input.name == 'Table-Columns-3'"
          />
        </template>
      </div>
    </section>
    <message-multimedia v-if="isSaveImage || isError || isSuccess" />
    <div
      class="bg-agrogo-yellow-1 h-14 space-x-3 md:space-x-4 lg:space-x-4 sticky bottom-0 z-50 z-50"
      :class="{ 'h-16 lg:h-20 md:h-20': isInvalid }"
    >
      <div class="flex items-center justify-center pt-2">
        <button
          @click.prevent="previus()"
          :disabled="isBusy2"
          type="button"
          class="btn-primary mr-4"
        >
          <span
            class="icon-desplegar flex items-center transform -rotate-180 text-agrogo-yellow-2 text-xs mr-1 leading-none"
          ></span>
          Anterior
        </button>
        <button
          @click.prevent="next()"
          :disabled="isBusy2"
          type="button"
          class="btn-primary"
        >
          <div class="loader" v-if="isBusy2" />
          <div v-else class="flex">
            Siguiente
            <span
              class="icon-desplegar flex items-center transform text-agrogo-yellow-2 text-xs ml-1 leading-none"
            ></span>
          </div>
        </button>
      </div>
      <p
        v-if="v$.$invalid"
        class="pt-1 pb-1 font-nexa font-normal text-xs lg:text-base md:text-base text-agrogo-green-2 text-left flex justify-center"
      >
        Completa los campos obligatorios (<span class="text-3xl ml-0 mr-0 h-0"
          >*</span
        >) para continuar.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import publishFarmMixin from "@/mixins/publishFarmMixin";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
import saveImageMixin from "@/mixins/saveImageMixin";
// Vuelidate
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// components
import InputSelect from "../globals/InputSelect";
import InputsHeight from "../globals/InputsHeight";
import TopographyComponent from "../globals/TopographyComponent";
import InputCheckMultiple from "../globals/InputCheckMultiple";
import InputTextArea from "../globals/InputTextArea";
import InputNumber from "../globals/InputNumber";
import InputText from "../globals/InputText";
import SelectMultiple from "../globals/SelectMultiple";
import TableFourColumn from "../globals/TableFourColumn";
import TableThreeColumn from "../globals/TableThreeColumn";
import TableTwoColumn from "../globals/TableTwoColumn";
import MessageMultimedia from "../globals/MessageMultimedia";
import InputNumberPercentage from "../globals/InputNumberPercentage.vue";
export default {
  inject: ["provider"],
  mixins: [publishFarmMixin, beforeWindowMixin, saveImageMixin],
  components: {
    InputSelect,
    InputsHeight,
    TopographyComponent,
    InputCheckMultiple,
    InputTextArea,
    InputText,
    InputNumber,
    SelectMultiple,
    TableFourColumn,
    TableThreeColumn,
    TableTwoColumn,
    MessageMultimedia,
    InputNumberPercentage,
  },
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      repository: me.provider.publishFarmRepository,
      repositoryEdit: me.provider.publishFarmEditRepository,
      slug: me.$route.params.slug,
      isBusy: true,
      isBusy2: false,
      isVisible: true,
      isVisible2: false,
      isTotal: false,
      isHeight: false,
      step4: true,
      dirty: true,
      firstLoad: false,
      isEditCheck: false,
      formData: {
        value_attributes: [],
      },
      nextStep: null,
    };
  },
  validations() {
    const me = this;
    let validation = null;
    let defaultValidation = {
      attributeValidations: {
        $each: helpers.forEach({
          value: {
            required,
          },
        }),
      },
      attributeCheckValidations: {
        $each: helpers.forEach({
          values: {
            required,
          },
        }),
      },
      attributeSelectMultipleValidations: {
        $each: helpers.forEach({
          values: {
            required,
          },
        }),
      },
      attributeSumation: {
        $each: helpers.forEach({
          isTotal: {
            checked(val) {
              return val;
            },
          },
        }),
      },
    };

    if (me.isVisible2) {
      validation = {
        ...defaultValidation,
        isTotal: {
          checked(val) {
            return val;
          },
        },
      };
    } else {
      validation = {
        ...defaultValidation,
      };
    }

    return validation;
  },
  computed: {
    ...mapState("publishFarm", [
      "step",
      "farmSave",
      "formData2",
      "formData3",
      "formData4",
      "isTopography",
      "dataSave",
      "step",
    ]),
    isInvalid() {
      const me = this;
      return me.v$.$invalid;
    },
  },
  methods: {
    ...mapActions("publishFarm", ["findStep", "updateMessage"]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async next() {
      const me = this;
      me.v$.$touch();
      if (!me.v$.$invalid) {
        if (
          me.step.number2 == 3 ||
          me.step.number2 == 4 ||
          me.farmSave?.isFarmSave
        ) {
          await me.save();
        } else {
          me.findStep(me.step.number2 + 1);
        }
      }
    },
    fillFormData() {
      const me = this;
      if (me.formData4) {
        me.formData = { ...me.formData4 };
        me.attributes.forEach((el) => {
          me.formData.value_attributes.forEach((x) => {
            if (x.attribute_id == el.id) {
              if (x.name && x.name == "selectMultiple") {
                el.value = x.value;
              } else if (
                el.type_attributes_input.name !== "Select" &&
                el.type_attributes_input.id !== 32 &&
                // el.type_attributes_input.id !== 34 &&
                // el.type_attributes_input.id !== 33 &&
                // el.type_attributes_input.name !== "Select-multiple" &&
                el.subattributes.length > 0
              ) {
                el.subattributes.forEach((y) => {
                  if (y.id == x.subattribute_id) {
                    y.value = x.value;
                  }
                });
              } else if (el.type_attributes_input.name == "Select") {
                el.value = x.subattribute_id;
              } else {
                el.value = x.value;
              }
            }

            // Campos requeridos
            me.attributeValidations.forEach((z) => {
              if (x.attribute_id == z.attribute_id) {
                if (
                  el.type_attributes_input.name !== "Select" &&
                  el.type_attributes_input.name !== "Checkbox" &&
                  el.type_attributes_input.id !== 32 &&
                  el.type_attributes_input.id !== 34 &&
                  el.type_attributes_input.id !== 33 &&
                  el.subattributes.length > 0
                ) {
                  el.subattributes.forEach((y) => {
                    if (
                      y.id == x.subattribute_id &&
                      z.subattribute_id == y.id
                    ) {
                      z.value = x.value;
                    }
                  });
                } else {
                  z.value = x.value;
                }
              }
            });

            // Campos checkbox requeridos
            me.attributeCheckValidations.forEach((z) => {
              if (el.type_attributes_input.name == "Checkbox") {
                if (x.attribute_id == z.attribute_id) {
                  el.subattributes.forEach((y) => {
                    if (y.value) {
                      const val = z.values.find(
                        (v) => v.id == x.subattribute_id
                      );

                      if (!val) {
                        z.values.push({
                          value: x.value,
                          id: x.subattribute_id,
                        });
                      }
                    }
                  });
                }
              }
            });

            // Campos select multimple requeridos
            me.attributeSelectMultipleValidations.forEach((z) => {
              if (x.attribute_id == z.id) {
                z.values = el.value;
              }
            });

            // Sumation requerida
            me.attributeSumation.forEach((z) => {
              if (x.attribute_id == z.id) {
                el.isTotal = true;
              }
            });
          });
        });
        me.isEditCheck = true;
        me.firstLoad = true;
      } else {
        me.attributeValidations.forEach((z) => {
          if (z.attribute_id == 29) z.value = "422";
        });
        me.attributes.forEach((el) => {
          if (el.id == 29) el.value = "422";
        });
      }
    },
    // setTotal(value, value2) {
    //   const me = this;
    //   me.isVisible = value;
    //   me.isTotal = value2;
    // },
    setHeight(value) {
      const me = this;
      me.isVisible2 = value;
      me.isHeight = value;
    },
    addAttributes() {
      const me = this;
      const animals = me.attributes.filter(
        (el) => el.org_control_name == "QTY_ANIMALS"
      );
      const index = me.attributes.findIndex(
        (el) => el.id_name == "DESCRIPCION_ADICIONAL"
      );
      const description = me.attributes.find(
        (el) => el.id_name == "DESCRIPCION_ADICIONAL"
      );

      if (animals.length > 0) {
        me.attributes.splice(index + 1, 0, {
          animals,
          name: "table-two-column",
          type_attributes_input: animals[0].type_attributes_input,
        });
      }
      me.attributes.splice(index, 1);
      me.attributes.push(description);
      me.fillFormData();
    },
  },
  created() {
    const me = this;
    me.nextStep = me.step.number2 + 1;
    if (me.step.number2 == 4) {
      me.updateMessage(
        `¿Salir de la página? Si abandonas perderas los cambios que realizaste en este paso ${me.step.number2}.`
      );
    } else {
      me.updateMessage(
        "¿Salir de la página? Si ya pasaste el paso 3 tu finca ha quedado guardada. De lo contrario si abandonas se perdera la información que llevas."
      );
    }
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    const me = this;
    me.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.loadAttributes();
    if (me.slug) {
      await me.loadAttributesEdit();
    }
    me.addAttributes();
  },
};
</script>
