<template>
  <section
    class="md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 lg:col-span-1 md:col-span-2 row-span-1"
  >
    <div class="grid grid-cols-2 place-content-center">
      <div class="flex items-center">
        <a class="font-nexa text-white text-sm text-left lg:text-base relative"
          >{{ item.name }}
          <span
            class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
            >*</span
          ></a
        >
      </div>
      <div class="flex items-center justify-end">
        <v-select
          :options="item.subattributes"
          v-model="v$.selected.$model"
          @search:blur="v$.selected.$touch()"
          :class="{
            'error-form-select-multiple': v$.selected.$error,
          }"
          :reduce="(option) => option.id.toString()"
          class="style-chooser3 w-full text-right md:max-w-236"
          label="name"
          :close-on-select="false"
          multiple
        >
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
    </div>
  </section>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  props: ["item", "values"],
  data() {
    return {
      v$: useVuelidate(),
      selected: this.values ? this.values : [],
    };
  },
  watch: {
    selected() {
      const me = this;
      me.$emit("addSubattributesSelectMultiple", me.item.id, me.selected);
    },
    values(newValue) {
      const me = this;
      me.selected = newValue;
    },
  },
  validations: {
    selected: { required },
  },
};
</script>
