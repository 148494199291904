<template>
  <section
    class="md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 lg:col-span-1 md:col-span-2 row-span-1"
  >
    <div class="flex justify-start mb-4">
      <a v-if="item" class="font-nexa text-white text-sm text-left lg:text-base"
        >{{ item.name }} {{ item.unit ? `(${item.unit})` : "" }}
      </a>
    </div>
    <textarea
      :id="item.id_name"
      :name="item.id_name"
      v-model="attribute"
      rows="10"
      cols="50"
      class="text-xs w-full text-white bg-transparent font-dinpro font-normal placeholder-gray-400 border-2 rounded-b-2xl rounded-r-2xl border-white text-left min-h-123 pl-2 pr-2 md:min-h-167"
      placeholder=""
      @keyup="$emit('addValueText', item.id, attribute)"
    ></textarea>
  </section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.attribute = newValue;
    },
  },
};
</script>
