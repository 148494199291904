<template>
  <div class="grid grid-cols-2 place-content-center">
    <div
      v-if="option"
      class="font-nexa text-white text-sm text-left md:text-base lg:text-lg relative mr-2"
    >
      {{ option.name }}
      <span
        class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
        >*</span
      >
    </div>

    <div class="flex items-center justify-end">
      <input
        :id="item.id_name"
        :name="item.id_name"
        v-model="v$.attribute.$model"
        @blur="v$.attribute.$touch()"
        :class="{ 'error-form-input': v$.attribute.$error }"
        type="number"
        class="input-standard-transparent md:w-full md:max-w-236"
        @keyup="addSubattributes"
      />
    </div>
  </div>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  emits: ["addSubattributes"],
  props: ["item", "value", "option"],
  data() {
    return {
      v$: useVuelidate(),
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("addSubattributes", me.option, me.attribute);
    },
  },
  validations: {
    attribute: { required },
  },
  methods: {
    addSubattributes() {
      const me = this;
      if (me.attribute == "") me.attribute = "";
      me.$emit("addSubattributes", me.option, me.attribute);
    },
  },
};
</script>
