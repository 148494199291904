<template>
  <div
    class="flex items-center justify-between md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5"
  >
    <div class="flex w-1/2">
      <div
        v-if="item"
        class="font-nexa bold text-white text-sm text-left md:md:max-w-198 md:text-base lg:text-lg relative"
      >
        {{ item.name }}
        <span
          class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
          >*</span
        >
      </div>
    </div>
    <div
      class="flex justify-center items-center mr-3 md:mr-0"
      v-for="(option, index) in options"
      :key="index"
    >
      <label
        :for="item.name + index + item.id"
        class="text-white text-xs md:text-base lg:text-lg font-dinpro font-normal block mr-2 hover:text-agrogo-yellow-1"
      >
        {{ option.text }}
      </label>
      <input
        :value="option.value"
        :id="item.name + index + item.id"
        :name="item.name + index + item.id"
        v-model="v$.attribute.$model"
        :class="{ 'error-form-input': v$.attribute.$error }"
        type="radio"
        class="custom-radio-test"
        @change="addValue"
      />
    </div>
  </div>
</template>

<script>
//Vuelidate
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      attribute: this.value,
      options: [
        { text: "Si", value: "1" },
        { text: "No", value: "0" },
      ],
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
    },
    attribute() {
      const me = this;
      me.$emit("addValueRadio", me.item.id, me.attribute);
    },
  },
  validations: {
    attribute: { required },
  },
};
</script>
