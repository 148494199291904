<template>
  <div class="card-img cursor-pointer">
    <button class="wrapper" />
    <img
      v-if="item.type == 'img'"
      class="h-full w-full object-cover"
      :src="item.src"
      loading="lazy"
      data-src="@/assets/images/loading.gif"
      data-srcset="@/assets/images/loading.gif"
      alt=""
    />
    <img
      v-else
      class="h-full w-full object-cover"
      :src="item.srcImg"
      loading="lazy"
      data-src="@/assets/images/loading.gif"
      data-srcset="@/assets/images/loading.gif"
      alt=""
    />
    <span class="number_ font-dinpro absolute leading-none text-xs p-1">
      {{ index }}
    </span>
    <button
      class="absolute z-10 right-0 leading-none text-2xl lg:text-3xl"
      @click="remove(index, item)"
      :disabled="isBusy"
    >
      <span class="icon-imgclose text-white opacity-90"></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    remove(index, item) {
      this.$emit("remove", index, item);
    },
  },
};
</script>

<style labg="css">
.number_ {
  background-color: #23443d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-bottom-right-radius: 4px;
}
</style>
