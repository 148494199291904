<template>
  <input
    :id="option.id"
    :name="option.id"
    v-model="attribute"
    :true-value="true"
    :false-value="false"
    type="checkbox"
    class="custom-checkbox-test"
    :class="{ ...className }"
    @change="$emit('addCheck', option, attribute)"
  />
  <label
    :for="option.id"
    class="font-nexa text-agrogo-gray-1 text-xs lg:text-base hover:text-agrogo-yellow-1 cursor-pointer"
    >{{ option.name }}</label
  >
</template>

<script>
export default {
  emits: ["addCheck"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: null,
    },
    option: {
      type: Object,
      default: null,
    },
    className: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
    },
  },
};
</script>
