<template>
  <div
    v-if="item"
    class="flex min-h-56 lg:min-h-80 items-center pl-10 pr-14 pt-5 pb-8 lg:pl-10 lg:pr-16 lg:pb-12"
    :class="item.isStyle"
  >
    <textarea
      v-for="(att, index) in item.value_attributes"
      :key="index"
      class="font-dinpro text-agrogo-black-1 text-xs text-left lg:text-lg w-full border-0 resize-none p-0"
      v-model="att.value"
      id="textarea"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    changeValue() {
      const textarea = document.getElementById("textarea");
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
  },
  mounted() {
    const me = this;
    me.changeValue();
  },
};
</script>
