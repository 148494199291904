<template>
  <div
    class="md:min-h-64 md:bg-agrogo-green-5 md:px-5 lg:col-span-1 md:col-span-2 row-span-1"
  >
    <div class="content-component">
      <a class="font-nexa text-white text-sm text-left lg:text-base relative"
        >Carga Animal
        <span
          class="text-agrogo-yellow-2 text-2xl ml-1 h-0 relative top-1 left-[-5px]"
          >*</span
        >
      </a>
    </div>
    <div class="table-container">
      <table class="table-auto table-cebra" aria-describedby="">
        <thead>
          <tr>
            <th></th>
            <th>Cant. de Animales <br />(Unidades)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="animal in item.animals" :key="animal?.id">
            <td>{{ animal?.name }}</td>
            <td>
              <input
                type="text"
                class="input-table input-standard-transparent w-16 md:w-28 lg:w-28"
                v-model="animal.value"
                @keyup="format(animal.id)"
                :class="{ 'error-form-input': animal.value == '' }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["addValueText"],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    format(id) {
      const me = this;
      me.item.animals.forEach((el) => {
        if (el.id == id) {
          el.value = me.formatValue(el.value);
          el.value = el.value == "0" ? "" : el.value;
          me.$emit("addValueText", el.id, el.value);
        }
      });
    },
    formatValue(value) {
      let attribute = 0;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      value = value.split(".").join("").replace(/\D/g, "");
      if (isNaN(value)) value = 0;
      attribute = formatter.format(value);
      return attribute;
    },
  },
};
</script>
<style lang="css">
.content-component {
  display: flex;
  justify-content: space-between;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}
/* Tamaño del scroll */
.table-container::-webkit-scrollbar {
  width: 37px;
  height: 5px;
}
/* Estilos barra (thumb) de scroll */
.table-container::-webkit-scrollbar-thumb {
  background: #f5ce3e !important;
  border-radius: 11px;
}

.table-container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #f5ce3e !important;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.table-cebra {
  width: 100%;
  border: 0;
}

.table-cebra thead {
  background-color: #23443d;
  border-bottom: 3px solid #153e35;
}

.table-cebra thead tr th {
  width: 200px;
}

.table-cebra tbody tr {
  background: rgba(196, 196, 196, 0.1) !important;
  border-bottom: 3px solid #153e35;
  color: white;
}

.table-cebra tbody tr:nth-child(2n) {
  background-color: #23443d !important;
}

.table-cebra th {
  background-color: transparent;
  color: #e5e5e5 !important;
  font-size: 14px;
  font-family: "Nexa", sans-serif;
  font-weight: 400;
  text-align: center !important;
}

.table-cebra th,
.table-cebra td {
  padding: 0.5rem;
  text-align: center;
}

.table-cebra td:first-child {
  text-align: left !important;
}

.table-cebra thead tr th:first-child {
  width: 400px;
}

.input-table {
  background: #153e35 !important;
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .content-component {
    flex-direction: column;
  }
}
</style>
