<template>
  <div>
    <div class="items-center md:items-start md:px-5 md:py-6 md:mx-40">
      <div
        class="flex flex-col px-4 md:pt-3 h-auto w-auto space-y-3 md:space-y-0"
      >
        <div class="flex items-center justify-center mt-7 md:mt-12">
          <img
            class="w-14 md:w-16"
            src="@/assets/images/iconsuccessful.svg"
            alt=""
          />
        </div>
        <div class="xl:flex xl:justify-center md:py-8">
          <div class="flex items-center px-3 md:px-0 md:w-5/5 xl:w-3/5">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1"
            ></a>
            <a class="font-dinpro text-white text-sm text-left lg:text-2xl"
              >La orden ha sido procesada y el pago aprobado.</a
            >
          </div>
        </div>
        <div class="md:flex md:justify-center pb-5 md:pb-8">
          <div class="flex items-center md:w-5/5 xl:w-3/5 px-3 md:px-0">
            <a
              class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1"
            ></a>
            <a class="font-dinpro text-white text-sm text-left lg:text-2xl"
              >Te notificaremos en máximo 5 días calendario, una vez TU FINCA
              sea revisada y aprobada por nuestro equipo.</a
            >
          </div>
        </div>
        <div class="flex justify-center">
          <div
            class="w-full px-3 md:px-0 md:w-5/5 xl:w-3/5 h-0.5 bg-agrogo-yellow-1 md:mt-8"
          ></div>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR BOTONES DE NAVEGACION -->
    <div class="bg-agrogo-green-2 px-4 pt-4 md:pt-2 pb-10">
      <div class="h-14 flex items-center justify-center space-x-4">
        <button
          @click="publishNew()"
          type="button"
          class="btn-primary flex items-center justify-center btn-size-step9 bg-agrogo-yellow-1 rounded-xl text-xs md:text-base text-agrogo-green-3"
        >
          Publicar otra finca
        </button>
        <button
          @click="next()"
          type="button"
          class="btn-primary flex items-center justify-center btn-size-step9 bg-agrogo-yellow-1 rounded-xl text-xs md:text-base text-agrogo-green-3"
        >
          Ir a mis fincas
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
export default {
  mixins: [beforeWindowMixin],
  data() {
    return {
      dirty: false,
    };
  },
  methods: {
    ...mapActions("publishFarm", ["findStep", "restoreData", "updateIsStep"]),
    publishNew() {
      const me = this;
      me.restoreData();
      me.findStep(1);
      me.$router.push("/publicar-finca");
    },
    next() {
      const me = this;
      me.restoreData();
      me.$router.push("/mis-fincas");
    },
  },
  beforeUnmount() {
    const me = this;
    me.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {
    const me = this;
    me.updateIsStep(false);
  },
};
</script>
