<template>
  <div
    class="bg-agrogo-yellow-1 h-20 flex items-center justify-center md:h-24 w-full relative"
    v-if="step"
  >
    <div class="flex items-center w-7/12 lg:w-10/12 md:w-9/12 justify-center">
      <div class="pr-3 pl-2">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >{{ step.number }}</span
        >
      </div>
      <div class="flex flex-col justify-center">
        <div class="flex items-star justify-star pb-3">
          <a
            class="font-nexa font-normal text-base text-agrogo-green-2 md:text-2xl"
          >
            <span class="font-nexa" v-html="step.name" />
          </a>
        </div>
        <div
          class="flex flex-row space-x-2 md:space-x-5 lg:space-x-6 md:text-base"
        >
          <span
            class="icon-punto"
            :class="{ 'active-punto': step.id == item.id }"
            v-for="(item, index) in steps"
            :key="index"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import saveImageMixin from "@/mixins/saveImageMixin";
export default {
  mixins: [saveImageMixin],
  computed: {
    ...mapState("publishFarm", ["steps", "step", "isSaveImage", "isError"]),
  },
};
</script>

<style lang="css">
.icon-punto {
  color: white !important;
}
.active-punto {
  color: #00754a !important;
}
</style>
